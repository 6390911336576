<template>
  <div class="box" v-on:scroll="EventBus.$emit('scroll-card-items', $event.target)">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}

        <small v-if="tasks_statistic" class="font-light">
          ({{ tasks_statistic.detailing }})
        </small>

        <button type="button"
                class="btn btn-success pull-right"
                v-on:click="EventBus.$emit('open-create-quick-task-modal')"
                title="Добавить задачу">
          <i class="fa fa-plus"></i>
        </button>
      </h3>
    </div>

    <funnel-tasks :task_type_id="16" :show_task_type_statuses="false" />
  </div>
</template>

<script>
import Vue from "vue";

Vue.component("funnel-tasks", (resolve) => {
  return require(["./../index.vue"], resolve);
});

export default {
  name: 'funnel-detailing',

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Детейлинг',

    tasks_statistic: null,
  }),


  async created() {
    try {
      this.EventBus.$on("set-tasks-statistic", tasks_statistic_data => {
        this.tasks_statistic = tasks_statistic_data;
      });
    } catch (error) {
      errorHandler(error);
    }
  },
};
</script>

<style scoped>

</style>
